


import "./frontend-utils/router.js";

import { locationChangeSubscribe } from "./frontend-utils/router.js";
import { matchRoute } from "./frontend-utils/router/route-desc.js";

import "./components/us-state-select.js";
import "./pages/page-real-estate-agents.js";


/* TODO const APP_TPL = document.createElement("template");
APP_TPL.innerHTML = `
  <style>
    @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css");

    body {
      overflow: scroll-y;
      scrollbar-color: blue;
      scrollbar-width: thin;
      min-height: 100vh;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    route-view {
      flex: 1;
    }
  </style>

  <main-header style="display: none;"></main-header>

  <route-view>
    <route-desc path="/articles" component="articles-page"></route-desc>
    <route-desc path="/about-us" component="about-us-page"></route-desc>
    <route-desc path="/contact-us" component="contact-us-page"></route-desc>
    <route-desc path="/speakers" component="speakers-page"></route-desc>
    <route-desc path="/faq" component="faq-page"></route-desc>
    <route-desc path="/job-posting" component="job-posting"></route-desc>
    <route-desc path="/jobs/:job_listing_id" component="home-page"></route-desc>
    <route-desc path="/jobs" component="home-page"></route-desc>
    <route-desc path="/login" component="login-page"></route-desc>
    <route-desc path="/privacy-policy" component="privacy-policy-page"></route-desc>
    <route-desc path="/terms-of-service" component="terms-of-service-page"></route-desc>
    <route-desc path="/" component="speakers-page"></route-desc>
  </route-view>

  <main-footer style="display: none;"></main-footer>
`;

document.body.appendChild(APP_TPL.content.cloneNode(true));*/


function findMatchingRoute(viewElem, path)
{
  console.log("Finding matching route", viewElem, path, viewElem.querySelectorAll("[data-route]"));
  for (const e of viewElem.querySelectorAll("[data-route]")) {
    const routeDesc = {
      path: e.dataset.route,
    };
    if (matchRoute(routeDesc, path)) {
      return e;
    }
  }
}


locationChangeSubscribe(document.body, (_, path) => {
  console.log("Location change", path);
  const landingPage = !path;
  if (landingPage) {
    document.body.classList.add("landing-page");
  } else {
    document.body.classList.remove("landing-page");
  }

  const viewElem = document.body.querySelector("main");
  const matchingRoute = findMatchingRoute(viewElem, path);
  for (const e of viewElem.querySelectorAll("[data-route]")) {
    if (e !== matchingRoute) {
      e.classList.remove("page-active");
    } else {
      e.classList.add("page-active");
    }
  }
});
