


import "./router/route-desc.js";
import "./router/route-link.js";
import "./router/route-view.js";



export function usingHash()
{
  return document.body.dataset.routerUseHash?.toLowerCase?.() === "true";
}

export function closestElement(selector, elem)
{
  const closestElem = elem.closest(selector);
  if (closestElem) { return closestElem; }

  if (elem.assignedSlot) elem = elem.assignedSlot;

  const root = elem.getRootNode();

  if (root === document || !(root instanceof ShadowRoot)) {
    return null;
  }

  return closestElement(selector, root.host);
}

export function getMountPath(elem)
{
  const closestElem = closestElement("[mount-path]", elem);
  if (closestElem) {
    return closestElem.getAttribute("mount-path");
  }
  return "/";
}


window.addEventListener("popstate", popStateEvent => {
  const ev = new Event("locationchange");
  ev.state = popStateEvent.state;
  window.dispatchEvent(ev);
})

const oldPushState = history.pushState;
history.pushState = function pushState() {
  let ret = oldPushState.apply(this, arguments);
  window.dispatchEvent(new Event("locationchange"));
  return ret;
};

const oldReplaceState = history.replaceState;
history.replaceState = function replaceState() {
  let ret = oldReplaceState.apply(this, arguments);
  window.dispatchEvent(new Event("locationchange"));
  return ret;
};


export function resolvePath(elem, path, queryArgs)
{
  const currentMountPath = elem ? getMountPath(elem) : "";
  const absolutePath = expandLocation(currentMountPath, path);
  const queryArgsStr = queryArgs ? "?" + queryArgs.toString() : "";
  if (usingHash()) {
    return `${location.pathname}${queryArgsStr}#${absolutePath}`;
  } else {
    return `${absolutePath}${queryArgsStr}`;
  }
}

export function pushLocation(elem, path, state, queryArgs)
{
  const location = resolvePath(elem, path, queryArgs);
  history.pushState(state, "", location);
}

export function replaceLocation(elem, path, state, queryArgs)
{
  const location = resolvePath(elem, path, queryArgs);
  history.replaceState(state, "", location);
}

export function pathJoin(...parts)
{
  const cleanedParts = parts
    .map(part => part[0] === "/" ? part.slice(1) : part)
    .filter(part => part !== "")
    .map(part => part[part.length-1] === "/" ? part.slice(0, -1) : part);
  let path = "/" + cleanedParts.join("/");
  return simplifyPathByRemovingRelativeParts(path);
}


const MOUNTS = new Map();


window.addEventListener("locationchange", ev => {
  for (const [_, sub] of MOUNTS) {
    sub.locationChange(ev);
  }
});

export function locationChangeSubscribe(elem, cb)
{
  const mountLocation = getMountPath(elem);
  const id = Symbol(`${mountLocation}:${elem.tagName}`);
  const unSubCb = () => {
    MOUNTS.delete(id);
  };

  const mount = new LocationChangeSub(mountLocation, cb);
  MOUNTS.set(id, mount);
  setTimeout(() => mount.locationChange(null), 0);
  return unSubCb;
}

function cleanMountLocation(mountLocation)
{
  if (mountLocation[mountLocation.length-1] === "/") {
    return mountLocation.slice(0, -1);
  }
  return mountLocation;
}

function simplifyPathByRemovingRelativeParts(path)
{
  let parts = path.split("/");
  let newPath = [];
  for (let part of parts) {
    if (part === "..") {
      newPath.pop();
    } else if (part !== ".") {
      newPath.push(part);
    }
  }
  return newPath.join("/");
}

export function expandLocation(mountLocation, location)
{
  mountLocation = cleanMountLocation(mountLocation);

  let path = location[0] === "/" ? location : `${mountLocation}/${location}`;

  return simplifyPathByRemovingRelativeParts(path);
}


class LocationChangeSub
{
  #mountLocation;
  #cb;
  #prevLocation = undefined;

  constructor(mountLocation, cb)
  {
    this.#mountLocation = mountLocation;
    this.#cb = cb;
  }

  locationChange(ev)
  {
    let path = currentPath();

    if (path.startsWith(this.#mountLocation)) {
        path = path.slice(this.#mountLocation.length);
        if (path[0] === "/") path = path.slice(1);
    } else {
        path = null;
    }
    if (path !== this.#prevLocation) {
        this.#prevLocation = path;
        this.#cb(this.#mountLocation, path, ev?.state);
    }
  }
}


export function currentPath()
{
  const path = usingHash() ?
    decodeURI(window.location.hash.slice(1))
    : decodeURI(window.location.pathname);

  return path[0] === "/" ? path : "/" + path;
}
