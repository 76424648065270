


import { locationChangeSubscribe, pathJoin } from "../router.js";


class RouteView extends HTMLElement {
  #childComponent = null;
  #prevMatch = null;
  #prevMatchRouteElem = null;
  #routerUnSub;
  #wrapper;

  connectedCallback()
  {
    this.#routerUnSub = locationChangeSubscribe(this, this.#locationChange.bind(this));

    this.style.flexGrow = "1";
    this.style.display = "flex";
    this.style.flexDirection = "column";

    this.#wrapper = document.createElement("div");
    this.#wrapper.style.flexGrow = "1";
    this.#wrapper.style.display = "flex";
    this.appendChild(this.#wrapper);
  }

  disconnectedCallback()
  {
    this.#routerUnSub();
    this.#wrapper.remove();
  }

  #locationChange(mountPath, path, state)
  {
    if (path === null) return;

    const matchResult = this.#firstMatchingPath(path);
    if (matchResult) {
      const {match, routeElem} = matchResult;

      if ((match.routePath === "/" || match.routePath === "") && path !== "/" && path !== "") {
        console.error(this, `mountPath=${mountPath} No matching route for path: ${path}`);
        return;
      }

      if (JSON.stringify(this.#prevMatch) === JSON.stringify(match)) { return; }
      this.#prevMatch = match;

      if (this.#childComponent && this.#childComponent.tagName.localeCompare(
          match.component, undefined, { sensitivity: "accent" },
        ) === 0)
      {
        this.#childComponent.state = state;
        this.#childComponent.queryArgs = match.queryArgs;
        this.#childComponent.routeArgs = match.routeArgs;
        return;
      } else {
        this.#replaceChild(mountPath, state, match, routeElem);
      }
    } else {
      // TODO: add a 404 page hook
      console.error(this, `mountPath=${mountPath} No matching route for path: ${path}`);
    }
  }

  #firstMatchingPath(path)
  {
    const routes = Array.from(this.childNodes).filter(e => e.tagName === "ROUTE-DESC");
    for (const routeElem of routes) {
      const match = routeElem.matchRoute(path);
      if (match) {
        return { match, routeElem };
      }
    }
    return null;
  }

  #replaceChild(mountPath, state, match, routeElem)
  {
    const prevChild = this.#childComponent;

    this.#childComponent = document.createElement(match.component);
    this.#childComponent.setAttribute("mount-path", pathJoin(mountPath, match.matching));
    this.#childComponent.state = state;
    this.#childComponent.style.width = "100%";
    this.#childComponent.style.flexGrow = "1";
    this.#childComponent.position = "absolute";
    this.#childComponent.style.display = "flex";
    this.#childComponent.style.flexDirection = "column";
    this.#childComponent.queryArgs = match.queryArgs;
    this.#childComponent.routeArgs = match.routeArgs;

    const pageDirection = routeElem.pageDirectionFrom(this.#prevMatchRouteElem);

    this.#childComponent.classList.add("route-view-page");
    switch (pageDirection) {
      case "forward":
        this.#childComponent.classList.add("route-view-page-forward-state");
        break;
      case "backward":
        this.#childComponent.classList.add("route-view-page-backward-state");
        break;
    }

    this.#childComponent.style.display = "none";

    setTimeout(() => {
      this.#childComponent.style.display = "flex";
      setTimeout(() => {
        this.#childComponent.classList.remove("route-view-page-backward-state");
        this.#childComponent.classList.remove("route-view-page-forward-state");
      }, 0);

      switch (pageDirection) {
        case "forward":
          prevChild.classList.add("route-view-page-backward-state");
          break;
        case "backward":
          prevChild.classList.add("route-view-page-forward-state");
          break;
      }

      if (prevChild) {
        const isAnimated = prevChild.getAnimations().length > 0;
        if (isAnimated)
        {
          prevChild.style.position = "absolute";
          prevChild.addEventListener("transitionend", () => prevChild.remove());
          prevChild.addEventListener("animationend", () => prevChild.remove());
        } else {
          prevChild.remove();
        }
      }

    }, 100);

    this.#prevMatchRouteElem = routeElem;
    this.#wrapper.appendChild(this.#childComponent);
  }
}


customElements.define("route-view", RouteView);
