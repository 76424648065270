// Copyright 2024 Fei Cui (feicui08@gmail.com), Daniel Varga (vargad88@gmail.com)



import { cacheTpl } from "../frontend-utils/utils.js";



const getElem = cacheTpl(`
  <style>
    :host {
      display: block;
    }

    #real-estate-agents-from > div {
      margin-bottom: 0.7rem;
    }

    .form-control {
      display: block;
      margin-top: 0.3rem;
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem;
      border: 1px solid #aaaaaa;
      border-radius: 0.5rem;
      background-color: white;
    }

    .form-control:focus {
      outline: none;
      border: 1px solid #5a86b4;
      box-shadow: 0 0 0.2rem rgba(255, 255, 255, 0.5);
    }

    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 0.5rem;
      background-color: #5a86b4;
      color: white;
    }

    .form-row {
      display: flex;
      gap: 0.7rem;
      flex-direction: row;
      align-content: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: stretch;
    }

    .form-row > div {
      flex-grow: 1;
    }
  </style>

  <main>
    <h1>Real Estate Agents</h1>
    <p>
      We'll get back with you as soon as possible to discuss how we can work together.
    </p>

    <form id="real-estate-agents-from">
      <div class="form-row">
        <div>
          <label for="first-name" class="form-label">First Name</label>
          <input type="text" class="form-control" id="first-name" name="first-name" required>
        </div>

        <div>
          <label for="last-name" class="form-label">Last Name</label>
          <input type="text" class="form-control" id="last-name" name="last-name" required>
        </div>
      </div>

      <div>
        <label for="agency-name" class="form-label">Agency Name</label>
        <input type="text" class="form-control" id="agency-name" name="agency-name" required>
      </div>

      <div>
        <label for="work-email" class="form-label">Work Email</label>
        <input type="email" class="form-control" id="work-email" name="work-email" required>
      </div>

      <div>
        <label for="work-phone" class="form-label">Work Phone</label>
        <input type="tel" class="form-control" id="work-phone" name="work-phone" required>
      </div>

      <div>
        <label for="work-website" class="form-label">Work Website</label>
        <input type="url" class="form-control" id="work-website" name="work-website" required>
      </div>

      <div>
        <label for="work-address" class="form-label">Work Address</label>
        <textarea type="text" class="form-control" id="work-address" name="work-address" required rows="3"></textarea>
      </div>

      <div>
        <label for="work-city" class="form-label">City</label>
        <input type="text" class="form-control" id="work-city" name="work-city" required>
      </div>

      <div class="form-row">
        <div>
          <label for="work-state" class="form-label">State</label>
          <us-state-select id="work-state" name="work-state" required>
          </us-state-select>
        </div>

        <div>
          <label for="work-zip" class="form-label">Zip Code</label>
          <input type="text" class="form-control" id="work-zip" name="work-zip" required>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
  </main>
`);


class PageRealEstateAgents extends HTMLElement {
  connectedCallback()
  {
    this.appendChild(getElem());
  }

  disconnectedCallback()
  {
    this.innerHTML = "";
  }
}


customElements.define("page-real-estate-agents", PageRealEstateAgents);
