


function routePathToRegexp(path)
{
  const parts = path.split("/");
  const regExpParts = parts.map(part => {
    if (part.startsWith(":")) {
      return `(?<${part.substr(1)}>[^/]+)`;
    }
    return part;
  });
  return new RegExp(`^${regExpParts.join("/")}`);
}


export function matchRoute(routeDesc, path)
{
  if (path[0] === "/") { path = path.slice(1); }
  const url = new URL(`${location.origin}/${path}`);
  const routePath = routeDesc.path;
  const regExp = routePathToRegexp(routePath)
  const match = url.pathname.match(regExp);
  if (match) {
    return {
      routePath,
      matching: match[0],
      component: routeDesc.component,
      queryArgs: Object.fromEntries(url.searchParams.entries()),
      routeArgs: match.groups || {},
    };
  }
  return null;
}


class RouteDesc extends HTMLElement {

  get routeDesc()
  {
    return {
      component: this.component,
      path: this.getAttribute("path"),
      nextPage: this.getAttribute("next-page"),
    };
  }

  get component()
  {
    return this.getAttribute("component");
  }

  matchRoute(path)
  {
    return matchRoute(this.routeDesc, path);
  }

  pageDirectionFrom(otherRouteElem)
  {
    if (!otherRouteElem) return null;
    if (otherRouteElem.getAttribute("next-page") === this.getAttribute("path")) {
      return "forward";
    }
    if (this.getAttribute("next-page") === otherRouteElem.getAttribute("path")) {
      return "backward";
    }
    return null;
  }
}


customElements.define("route-desc", RouteDesc);
