


export function cacheTpl(template_str)
{
  let cache = null;
  return () => {
    if (cache === null) {
      const e = document.createElement("template");
      e.innerHTML = template_str;
      cache = e.content;
    }
    return cache.cloneNode(true);
  }
}
